<template>
    <Popover v-model:open="isOpen">
        <PopoverTrigger as-child>
            <Button class="group" variant="primary">
                <span>{{ $t("Search.Availability") }}</span>
                <i class="fass fa-chevron-right group-data-[state=open]:rotate-90 transition-transform"></i>
                <Badge v-if="filterValues.inStock" variant="primary" @click.stop="onBadgeClick()">
                    <span> {{ $t("Global.InStock") }}</span>
                    <i class="fass fa-xmark"></i>
                </Badge>
            </Button>
        </PopoverTrigger>
        <PopoverContent align="start">
            <Command v-model="filterValues.inStock">
                <CommandList>
                    <CategoryFilterItem :value="true" @click="isOpen = false">
                        {{ $t("Global.InStock") }}
                    </CategoryFilterItem>
                    <CategoryFilterItem :value="false" @click="isOpen = false">
                        {{ $t("Search.Any") }}
                    </CategoryFilterItem>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
</template>
<script setup lang="ts">
const isOpen = ref(false);
const filterValues = useStateFilterValues();

function onBadgeClick() {
    filterValues.value.inStock = false;
    isOpen.value = false;
}
</script>
