<template>
    <div id="part-category-parts" class="container-fluid">
        <div class="overflow-x-auto">
            <table class="w-full">
                <thead>
                    <tr class="text-xs align-bottom bg-gray-700 text-white">
                        <th class="bg-gray-700 sticky p-2 left-0 z-10 text-left">
                            {{ $t("Search.Part_Number") }}
                        </th>
                        <th class="text-left p-2 pl-4">{{ $t("Global.Stock") }}</th>
                        <th class="p-2">{{ $t("Global.Datasheet") }}</th>
                        <th class="p-2 min-w-48 text-left">{{ $t("Search.Description") }}</th>
                        <th v-for="col in filterColumns" :key="col.key" class="p-2">{{ col.label }}</th>
                    </tr>
                </thead>
                <tbody :class="{ 'opacity-50': partsStatus == 'pending' }">
                    <tr
                        v-for="row in partsFlattened"
                        :key="row.Key"
                        :data-mpn="row.PartNumber"
                        :data-hash="row.PartClickHash"
                        class="align-top *:bg-white *:odd:bg-gray-50 border-b">
                        <td class="part-col sticky p-2 left-0 z-10">
                            <div class="flex gap-4 w-80">
                                <PartImage :part="row" size="md" />
                                <div>
                                    <div class="text-sm">{{ row.ManufacturerName }}</div>
                                    <NuxtLink class="text-base font-bold" :to="row.PartUrl">
                                        {{ row.PartNumber }}
                                    </NuxtLink>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="w-48 text-sm pt-2 px-3">
                                <span v-if="row.TotalStock > 0" class="text-success">
                                    {{ row.TotalStock.toLocaleString() }} {{ $t("Global.InStock") }}
                                    <i class="fass fa-check"></i>
                                </span>
                                <span v-else class="text-muted">{{ $t("Global.OutOfStock") }}</span>
                            </div>
                            <Button variant="link" @click="viewPartResults(row)">
                                {{ $t("PartCategory.ViewPriceAndAvailability") }}
                            </Button>
                        </td>
                        <td
                            class="text-center py-2"
                            :data-hash="row.PartClickHash"
                            :data-mpn="row.PartNumber"
                            :data-source="
                                props.series ? ANALYTICS_SOURCE.PRODUCT_SERIES : ANALYTICS_SOURCE.PART_CATEGORY
                            "
                            :data-dist="
                                row.DatasheetDistributor
                                    ? `${row.DatasheetDistributor.Name} [${row.DatasheetDistributor.Id}]`
                                    : undefined
                            "
                            :data-mfr="`${row.ManufacturerName} [${row.ManufacturerId}]`">
                            <a
                                v-if="row.DatasheetUrl"
                                class="inline-block"
                                :href="row.DatasheetUrl"
                                target="_blank"
                                rel="noopener nofollow"
                                data-ga="s:Datasheet">
                                <EnhancedImage
                                    :src="pdfIconUrl"
                                    :alt="$t('PartDetail.ViewDatasheet')"
                                    height="24"
                                    width="24" />
                            </a>
                        </td>
                        <td class="text-xs p-2">{{ row.Description }}</td>
                        <td v-for="col in filterColumns" :key="col.key" class="text-xs p-2">
                            {{ row[col.key] }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <PartResultsDialog
            ref="partDialog"
            :analytics-source="props.series ? ANALYTICS_SOURCE.PRODUCT_SERIES : ANALYTICS_SOURCE.PART_CATEGORY"
            @click="onPartResultsClick" />

        <PaginationControls
            v-if="partsData"
            id="category-parts-pagination"
            v-model:page="page"
            v-model:size="pageSize"
            class="py-4"
            :num-items="partsData.Pagination.Total"
            :page-size-options="pageSizes">
            <Spinner v-if="partsStatus == 'pending'" />
        </PaginationControls>
    </div>
</template>
<script setup lang="ts">
import { refDebounced } from "@vueuse/core";
import { PartResultsDialog } from "#components";
import type { ClickSource } from "../part/PartResultsDialog.vue";
import type { ParametricSearchPartsResponse } from "~/utils/types/ParametricSearchPartsResponse";

type FlattenedCategoryPart = {
    Key: string;
    ImageUrl: string;
    IsSelfHostedImage: boolean;
    PartNumber: string;
    ManufacturerId: number;
    ManufacturerName?: string;
    DatasheetUrl: string;
    PartUrl: string;
    Description: string;
    PartClickHash: string;
    TotalStock: number;
    DatasheetDistributor: {
        Name: string;
        Id: number;
    };
} & Record<string, string>;

const route = useRoute();
const router = useRouter();
const localePath = useLangPath();
const { gtag } = useGtag();
const page = ref(queryValueToNumber("page", 1));
const pageSizes = [10, 20];
const pageSize = ref(queryValueToNumber("pageSize", pageSizes[0]));
const filterValues = useStateFilterValues();
const filterOptions = useStateFilterOptions();
const category = useStateCategory();
const config = useRuntimeConfig();
const partDialog = ref<typeof PartResultsDialog | null>(null);
const cdnUrl = config.public.cdnUrl;
const pdfIconUrl = cdnUrl + "/assets/images/adobe_pdf_file_icon_24x24.png";

const props = defineProps<{ series?: ProductSeries }>();

const gtagParams = props.series
    ? { base_part_number: props.series.BasePartNumber }
    : { category_name: category.value?.Name };

const filterValuesQuery = computed(() => {
    const fv = filterValues.value;
    const mfgId = category.value?.Manufacturer?.Id.toString();
    const manufacturers = mfgId ? [mfgId] : fv.manufacturers;
    const query = Object.fromEntries(
        Object.entries(fv.specs)
            .filter((entry) => entry[1].length > 0)
            .map(([k, v]) => [k, v.join(",")])
    );
    const productSeriesId = props.series ? props.series.Id : undefined;

    return { inStock: fv.inStock, manufacturers, productSeriesId, ...query };
});

const partsQuery = computed(() => {
    return {
        page: page.value,
        pageSize: pageSize.value,
        ...filterValuesQuery.value,
    };
});

const filterColumns = computed(() =>
    filterOptions.value ? filterOptions.value.map((f) => ({ key: "spec_" + f.PartSpecificationId, label: f.Name })) : []
);

const partsFlattened = computed<FlattenedCategoryPart[]>(() => {
    if (!partsData.value?.Parts) return [];
    return partsData.value.Parts.map((p) => {
        const company = partsData.value?.Companies[p.ManufacturerId];
        const specs: Record<string, string> = {};
        p.Specs.forEach((s) => {
            specs["spec_" + s.PartSpecificationId] = s.Value;
        });

        const datasheetDistributorParts = p.DistributorResults?.filter(
            (c) => c.Distributor?.Id == p.DatasheetDistributorId
        );
        const datasheetDistributor =
            datasheetDistributorParts && datasheetDistributorParts.length
                ? datasheetDistributorParts[0].Distributor
                : undefined;

        return {
            Key: p.Key,
            ImageUrl: p.ImageUrl,
            IsSelfHostedImage: p.IsSelfHostedImage,
            PartNumber: p.PartNumber,
            ManufacturerId: p.ManufacturerId,
            ManufacturerName: company?.Name,
            DatasheetUrl: p.DatasheetUrl,
            DatasheetDistributor: datasheetDistributor
                ? { Name: datasheetDistributor.Name, Id: datasheetDistributor.Id }
                : undefined,
            PartUrl: localePath(["/part", company?.VirtualPath, p.PartNumberEncoded].join("/")),
            Description: p.Description,
            PartClickHash: p.PartClickHash,
            TotalStock: p.TotalStock,
            ...specs,
        } as FlattenedCategoryPart;
    });
});

function queryValueToNumber(key: string, minimum: number = 1): number {
    const value = route.query[key];
    if (!value) return minimum;
    const numValue = parseInt((Array.isArray(value) ? value[0] : value) ?? "");
    return isNaN(numValue) ? minimum : Math.max(numValue, minimum);
}

function queryValueToSpecValues(key: string): string[] {
    const value = route.query[key];
    if (!value) return [];
    return Array.isArray(value) ? value.map((q) => q?.toString() ?? "") : [value];
}

// load filter values from URL
function initializeFilterValues() {
    filterValues.value = {
        inStock: route.query.inStock == "true",
        manufacturers: queryValueToSpecValues("manufacturers"),
        specs: Object.fromEntries(
            filterOptions.value.map((opt) => [
                opt.PartSpecificationId,
                queryValueToSpecValues(opt.PartSpecificationId.toString()),
            ])
        ),
    };
}

const { data: partsData, status: partsStatus } = await useFetchApi<ParametricSearchPartsResponse>(
    props.series ? `api/product-series/parts` : `api/category/${category.value?.Id}/parts`,
    { query: refDebounced(partsQuery, 150), deep: false, dedupe: "defer" } // debouncing reduces double fetches
);

function viewPartResults(part: FlattenedCategoryPart) {
    gtag("event", "parametric_search_view_availability", {
        ...gtagParams,
        ...{
            part_number: part.PartNumber,
            manufacturer_name: part.ManufacturerName,
        },
    });

    if (partDialog.value) {
        const originalPart = partsData.value?.Parts.find((p) => p.Key === part.Key);
        partDialog.value.openCategoryPart(originalPart, category.value, part);
    }
}

function onPartResultsClick(source: ClickSource, part: CommonPart | null) {
    if (!part) return;

    const localGtagParams = {
        ...gtagParams,
        ...{
            part_number: part.PartNumber,
            manufacturer_name: part.Manufacturer.Name,
        },
    };

    if (source == "datasheet") {
        gtag("event", "parametric_search_modal_datasheet", localGtagParams);
    } else if (source === "distributor_datasheet") {
        gtag("event", "parametric_search_distributor_datasheet", localGtagParams);
    } else if (source === "distributor_part") {
        gtag("event", "parametric_search_distributor_part", localGtagParams);
    }
}

function resetPage() {
    page.value = 1;
}

// update URL
function updateRouteQuery() {
    const { inStock, page, pageSize, manufacturers, productSeriesId, ...others } = partsQuery.value;
    const query: Record<string, string | string[]> = { ...others };
    if (inStock) query.inStock = "true";
    if (page > 1) query.page = page.toString();
    query.pageSize = pageSize.toString();
    if (!category.value?.Manufacturer) query.manufacturers = manufacturers;
    router.replace({ query });
}

function updateCanonical() {
    const { page } = partsQuery.value;
    if (!page || page <= 1) {
        useSetCanonicalUrl(route.path);
    }
}

onMounted(() => {
    // set up watches on the client only (otherwise could lead to SSR recursion error)
    watch(pageSize, resetPage);
    watch(filterValuesQuery, resetPage);
    watch(partsQuery, updateRouteQuery);
    watch(filterOptions, initializeFilterValues, { immediate: true });
    watch(route, updateCanonical);
    // Run once to make sure we have the correct canonical url when mounted
    updateCanonical();
});
</script>
<style>
.part-col:before {
    height: calc(100% + 1px);
    top: -1px;
    content: "";
    width: 20px;
    right: -20px;
    background: linear-gradient(90deg, #0000000d 0%, #0000 100%);
    position: absolute;
    pointer-events: none;
}
</style>
