<template>
    <Popover>
        <PopoverTrigger as-child>
            <Button class="group" variant="primary">
                <span>{{ props.filter.Name }}</span>
                <i class="fass fa-chevron-right group-data-[state=open]:rotate-90 transition-transform"></i>
                <Badge v-for="s in selected" :key="s" variant="primary" @click.stop="remove(s)">
                    <span>{{ s }}</span>
                    <i class="fass fa-xmark"></i>
                </Badge>
            </Button>
        </PopoverTrigger>
        <PopoverContent align="start">
            <Command v-model="selected" multiple>
                <CommandInput />
                <CommandList>
                    <CategoryFilterItem v-for="option in props.filter.Options" :key="option" :value="option">
                        {{ option }}
                    </CategoryFilterItem>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
</template>
<script setup lang="ts">
const props = defineProps<{ filter: FilterOption }>();
const selected = defineModel<string[] | undefined>({ required: true });

function remove(item: string) {
    if (selected.value) selected.value = selected.value.filter((i) => i !== item);
}
</script>
