<template>
    <div id="part-category-filters" class="container-fluid flex flex-wrap gap-2 items-center">
        <CategoryFilterInStock />
        <CategoryFilterManufacturers v-if="showManufacturerFilter" v-model="filterValues.manufacturers" />
        <CategoryFilter
            v-for="filter in filterOptions"
            :key="filter.PartSpecificationId"
            v-model="filterValues.specs[filter.PartSpecificationId]"
            :filter="filter" />
        <Button v-if="hasSelection" variant="warning" fill="solid" @click="resetSelected()">
            <span>{{ $t("Global.ClearFilters") }}</span>
        </Button>
    </div>
</template>
<script setup lang="ts">
const category = useStateCategory();
const filterValues = useStateFilterValues();
const filterOptions = useStateFilterOptions();

function resetSelected() {
    const specs = Object.fromEntries(filterOptions.value.map((opt) => [opt.PartSpecificationId, []]));
    filterValues.value = { inStock: false, manufacturers: [], specs };
}

const hasSelection = computed(() => {
    const fv = filterValues.value;
    if (fv.inStock || fv.manufacturers.length > 0) return true;
    return Object.values(fv.specs).some((v) => v.length > 0);
});

const showManufacturerFilter = computed(
    () => !category.value?.Manufacturer && (category.value?.Children?.length ?? 0) === 0
);
</script>
