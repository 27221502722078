<template>
    <Popover>
        <PopoverTrigger as-child>
            <Button class="group" variant="primary">
                <span>{{ $t("PartCategory.ECIAManufacturers") }}</span>
                <i class="fass fa-chevron-right group-data-[state=open]:rotate-90 transition-transform"></i>
                <Badge v-for="s in selected" :key="s" variant="primary" @click.stop="remove(s)">
                    <span>{{ namesById[s] }}</span>
                    <i class="fass fa-xmark"></i>
                </Badge>
            </Button>
        </PopoverTrigger>
        <PopoverContent align="start">
            <Command v-model="selected" v-model:search-term="searchTerm" multiple>
                <CommandInput />
                <CommandList>
                    <CategoryFilterItem
                        v-for="mfg in filteredManufacturers"
                        :key="mfg.ManufacturerId"
                        :value="mfg.ManufacturerId">
                        {{ mfg.Name }}
                    </CategoryFilterItem>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
</template>
<script setup lang="ts">
const category = useStateCategory();
const selected = defineModel<string[]>({ required: true });
const searchTerm = ref("");

const { data: manufacturers } = await useFetchApi<Manufacturer[]>(`api/category/${category.value?.Id}/manufacturers`);

const namesById = computed(() => {
    if (!manufacturers.value) return {};
    return Object.fromEntries(manufacturers.value.map((m) => [m.ManufacturerId, m.Name]));
});

const filteredManufacturers = computed(() => {
    const search = searchTerm.value.trim().toLowerCase();
    const list = manufacturers.value?.slice().sort((a, b) => a.Name.localeCompare(b.Name));
    return search === "" || !Array.isArray(list)
        ? (list ?? [])
        : list.filter((mfg) => mfg.Name.toLowerCase().includes(search));
});

function remove(item: string) {
    selected.value = selected.value.filter((i) => i !== item);
}
</script>
